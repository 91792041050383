import { reactive, onMounted, ref } from 'vue';

export default function usePageSize() {
  const pageWidth = ref(0);
  let timerId = null;
  onMounted(() => {
    window.onresize = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        pageWidth.value = document.body.clientWidth;
      }, 100);
    };
  });

  return reactive({
    pageWidth,
  });
}

<template>
  <div class="reception-cold-storage-detail">
    <div class="header">{{ detailData.name }}</div>
    <div class="w">
      <div class="swiper" v-show="imgs.length > 1 && activeIndex === 1">
        <div class="swiper-container">
          <div class="swiper-wrapper">
            <div class="swiper-slide" v-for="(item, index) in imgs" :key="index">
              <img :src="item" alt="">
            </div>
          </div>
        </div>
      </div>
      <div class="one-img" v-show="imgs.length === 1 && activeIndex === 1">
        <img :src="imgs[0]" alt="">
      </div>
      <div class="video-box video-box2 video-box3" v-show="activeIndex === 2">
        <video ref="videoRef" controls></video>
      </div>
      <div class="nav">
        <div class="img-box1 curr" @click="toNav(1)">
          <img src="@/assets/images/coldStorage-image/photo.png" alt="">
        </div>
        <div class="img-box2 curr" @click="toNav(2)">
          <img src="@/assets/images/coldStorage-image/video.png" alt="">
        </div>
        <div class="img-box3 curr" @click="downClick">
          <img src="@/assets/images/coldStorage-image/manual.png" alt="">
        </div>
      </div>
      <div class="survey">
        <div class="title">园区概况</div>
        <div class="content" v-html="detailData.briefText"></div>
      </div>
      <div class="contact">
        <div class="title">联系我们</div>
        <div class="content">与穗深冷链联系，获取更多产业园区信息。</div>
        <div class="phone">0755-82263776</div>
      </div>
      <div class="back curr" @click="back">
        <img src="@/assets/images/case-image/back.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import {
  onMounted, reactive, toRefs, watch, nextTick, watchEffect,
} from 'vue';
import Swiper from 'swiper';
import 'swiper/css/swiper.css';
// import img from '@/assets/images/case-image/page.png';
// import img2 from '@/assets/images/home-image/carsol.png';
// import img3 from '@/assets/images/coldStorage-image/lengku.png';
import usePageSize from '@/hooks/use-page-size';
import { useRouter } from 'vue-router';
import apis from '@/request/apis';
// import { showErrorMsg } from '@/utils';
// import Hls from '@/utils/hls';
import Hls from 'hls.js';

export default {
  props: ['id'],
  setup(props) {
    const pageWidth = usePageSize();
    const router = useRouter();
    const data = reactive({
      mySwiper: null,
      imgs: [

      ],
      detailData: {},
      activeIndex: 1,
      videoRef: null,
    });
    let mySwiper = null;
    const options = {
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      autoplay: true, // 是否自动播放 true 是  false 否
      slidesPerView: 2, // 一排展示几个
      effect: 'coverflow', // 轮播图的切换效果  coverflow 3D
      centeredSlides: true, // 设定为true时，active slide会居中，而不是默认状态下的居左。
      spaceBetween: '-50%', // 每个轮播图之间设置距离（单位px）。
      loop: true, // 是否循环播放
      coverflowEffect: { // cover flow是类似于苹果将多首歌曲的封面以3D界面的形式显示出来的方式。coverflow效果参数，可选值：
        rotate: 0, // slide做3d旋转时Y轴的旋转角度
        stretch: 50, // 每个slide之间的拉伸值，越大slide靠得越紧。5.3.6 后可使用%百分比
        depth: 149, // slide的位置深度。值越大z轴距离越远，看起来越小。
        modifier: 4, // depth和rotate和stretch的倍率，相当于depth*modifier、rotate*modifier、stretch*modifier，值越大这三个参数的效果越明显
        slideShadows: false, // 是否开启slide阴影
      },
    };
    onMounted(() => {
      document.querySelector('#app').scrollTop = 0;
      const width = document.body.clientWidth;
      if (width <= 1700 && width > 1200) {
        options.coverflowEffect.depth = 110;
      } else if (width <= 1200 && width > 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.3;
      } else if (width <= 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.1;
      }
      mySwiper = new Swiper('.swiper-container', options);
    });

    watch(() => pageWidth, (newVal) => {
      const width = newVal.pageWidth;
      mySwiper.destroy(true, true);
      options.coverflowEffect.stretch = 50;
      options.coverflowEffect.depth = 149;
      options.coverflowEffect.modifier = 4;
      if (width <= 1700 && width > 1200) {
        options.coverflowEffect.depth = 110;
      } else if (width <= 1200 && width > 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.3;
      } else if (width <= 992) {
        options.coverflowEffect.stretch = 70;
        options.coverflowEffect.depth = 180;
        options.coverflowEffect.modifier = 2.1;
      } else {
        options.coverflowEffect.depth = 149;
      }
      mySwiper = new Swiper('.swiper-container', options);
    }, { deep: true });

    const back = () => {
      router.push({
        name: 'receptionColdStorage',
      });
    };

    async function loadDetail() {
      const res = await apis.receptionColdStorage.getDetail({ id: props.id });
      data.detailData = res;
      data.imgs = res.linkedPic?.split?.(',') ?? [];
      nextTick(() => {
        mySwiper.destroy(true, true);
        mySwiper = new Swiper('.swiper-container', options);
      });
    }

    loadDetail();

    const toNav = (index) => {
      data.activeIndex = index;
    };

    watchEffect(async () => {
      if (data.detailData.linkedVideo) {
        let res = await apis.upload.getM3U8({
          annexId: data.detailData.linkedVideo,
        });
        res = res.replace('/www/video/', '/');
        if (!data.videoRef) return;
        const hls = new Hls();
        hls.loadSource(res);
        hls.attachMedia(data.videoRef);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {});
      }
    });

    const downClick = () => {
      if (!data.detailData.handBook) {
        // showErrorMsg('没有上传手册, 不能下载');
        return;
      }
      // window.location.href = data.detailData.handBook;
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = data.detailData.handBook;
      a.download = data.detailData.handBook;
      a.target = '_target';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };

    return {
      ...toRefs(data),
      back,
      toNav,
      downClick,
    };
  },
};
</script>

<style scoped lang="scss">
.reception-cold-storage-detail {
  margin-top: 100px;
  margin-bottom: 200px;
  .video-box {
    margin-top: 32px;
    margin-bottom: 20px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 10px;
    // height: 200px;
    video {
      width: auto;
      height: 100%;
      display: block;
      margin: 0 auto;
      // height: 100%;
    }
  }
  .one-img {
    margin: 0 auto;
    margin-top: 52px;
    margin-bottom: 66px;
    padding: 0px 60px;
    height: 700px;
    width: 1185px;
    overflow: hidden;
    border-radius: 40px;
    object-fit: cover;
    img {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: 40px;
      // object-fit: cover;
    }
  }
  .header {
    padding: 66px 88px 78px;
    background-color: #31499F;
    font-size: 54px;
    font-weight: bold;
    color: #FFFFFF;
  }
  .survey {
    .title {
      font-size: 54px;
      font-weight: bold;
      color: #000000;
      line-height: 54px;
    }
    .content {
      margin-top: 41px;
      text-indent: 2em;
      font-size: 22px;
      font-weight: 400;
      color: #000000;
      line-height: 35px;
    }
  }
  .contact {
    .title {
      font-size: 54px;
      font-weight: bold;
      color: #000000;
      line-height: 54px;
      margin-top: 45px;
      margin-bottom: 50px;
    }
    .content {
      font-size: 22px;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 16px;
    }
    .phone {
      font-size: 34px;
      font-weight: bold;
      color: #31499F;
      line-height: 34px;
    }
  }
  .nav {
    display: flex;
    margin-bottom: 82px;
    margin-top: 10px;
    .img-box1,
    .img-box2,
    .img-box3 {
      width: 68px;
      // height: 30px;
      margin-right: 70px;
      img {
        display: block;
        width: 100%;
        // height: 100%;
        // object-fit: cover;
      }
    }
    .img-box3 {
      width: 96px;
    }
  }
  .video-box2 {
    margin-top: 52px;
    margin-bottom: 66px;
    padding: 0px 180px;
    height: 550px;
  }
  .swiper {
    margin-top: 52px;
    margin-bottom: 66px;
    padding: 0px 180px;
    height: 550px;
    .swiper-slide {
      border-radius: 40px;
      overflow: hidden;
      width: 1185px;
    }
    img {
      width: 100%;
      height: 100%;
      display: block;
      border-radius: 40px;
      object-fit: cover;
    }
    .swiper-container {
      width: 100%;
      height: 100%;
    }
  }
  .back {
    width: 108px;
    height: 46px;
    float: right;
    margin-top: 40px;
    margin-bottom: 86px;
    img {
      display: block;
      width: 100%;
      height: 100%;
      // object-fit: cover;
    }
  }
}
/* 当页面宽度大于1200px小于1700ppx */
@media screen and (min-width: 1200px) and (max-width: 1700px) {
  .reception-cold-storage-detail {
    .header {
      padding: 56px 68px 48px;
      background-color: #31499F;
      font-size: 48px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 60px;
      height: 462px;
      width: 810px;
      overflow: hidden;
      border-radius: 40px;
      object-fit: cover;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
    .video-box2 {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 180px;
      height: 380px;
    }
    .swiper {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 180px;
      height: 380px;
      .swiper-slide {
        border-radius: 40px;
        overflow: hidden;
        width: 810px;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 40px;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
/* 当页面宽度大于992px小于1200ppx */
@media screen and (min-width: 992px) and (max-width: 1200px) {
  .reception-cold-storage-detail {
    margin-top: 100px;
    margin-bottom: 150px;
    .header {
      padding: 46px 48px 48px;
      background-color: #31499F;
      font-size: 38px;
      font-weight: bold;
      color: #FFFFFF;
    }
    .survey {
      .title {
        font-size: 40px;
        line-height: 40px;
      }
      .content {
        margin-top: 41px;
        text-indent: 2em;
        font-size: 20px;
        line-height: 35px;
      }
    }
    .contact {
      .title {
        font-size: 40px;
        line-height: 40px;
        margin-top: 35px;
        margin-bottom: 40px;
      }
      .content {
        font-size: 20px;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .phone {
        font-size: 34px;
        font-weight: bold;
        color: #31499F;
        line-height: 34px;
      }
    }
    .nav {
      display: flex;
      margin-bottom: 82px;
      .img-box1,
      .img-box2,
      .img-box3 {
        width: 68px;
        height: 30px;
        margin-right: 70px;
        img {
          display: block;
          width: 100%;
          height: 100%;
        }
      }
      .img-box3 {
        width: 96px;
      }
    }
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 60px;
      height: 384px;
      width: 654px;
      overflow: hidden;
      border-radius: 40px;
      object-fit: cover;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 40px;
        object-fit: cover;
      }
    }
    .video-box2 {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 120px;
      height: 300px;
    }
    .swiper {
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 120px;
      height: 300px;
      .swiper-slide {
        width: 654px;
        border-radius: 40px;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 40px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .back {
      width: 88px;
      height: 36px;
      float: right;
      margin-top: 30px;
      margin-bottom: 66px;
    }
  }
}
/* 当页面宽度大于768px小于992ppx */
@media screen and (min-width: 768px) and (max-width: 992px) {
  .reception-cold-storage-detail {
    margin-top: 60px;
    margin-bottom: 100px;
    .video-box {
      video {
        width: 100%;
      }
    }
    .header {
      padding: 26px 28px 28px;
      font-size: 28px;
    }
    .survey {
      .title {
        font-size: 32px;
        line-height: 32px;
      }
      .content {
        margin-top: 41px;
        text-indent: 2em;
        font-size: 18px;
        line-height: 32px;
      }
    }
    .contact {
      .title {
        font-size: 32px;
        line-height: 32px;
        margin-top: 35px;
        margin-bottom: 32px;
      }
      .content {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 16px;
      }
      .phone {
        font-size: 22px;
        font-weight: bold;
        color: #31499F;
        line-height: 22px;
      }
    }
    .nav {
      margin-bottom: 42px;
      .img-box1,
      .img-box2,
      .img-box3 {
        width: 68px;
        height: 30px;
        margin-right: 70px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
      }
      .img-box3 {
        width: 96px;
      }
    }
    .one-img {
      margin: 0 auto;
      margin-top: 52px;
      margin-bottom: 66px;
      padding: 0px 16px;
      width: 530px;
      height: 285px;
      overflow: hidden;
      border-radius: 20px;
      object-fit: cover;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    .video-box3 {
      margin-top: 32px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: auto;
    }
    .swiper {
      margin-top: 24px;
      margin-bottom: 24px;
      padding: 0px 60px;
      height: 220px;
      .swiper-slide {
        border-radius: 20px;
        width: 530px;
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 20px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .back {
      width: 88px;
      height: 36px;
      float: right;
      margin-top: 30px;
      margin-bottom: 66px;
    }
  }
}
/* 当页面宽度小于768px */
@media screen and (max-width: 768px) {
  .reception-cold-storage-detail {
    margin-top: 60px;
    margin-bottom: 100px;
    .video-box {
      video {
        width: 100%;
      }
    }
    .video-box3 {
      margin-top: 32px;
      margin-bottom: 20px;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0 10px;
      height: auto;
    }
    .header {
      padding: 26px 28px 28px;
      font-size: 28px;
    }
    .survey {
      padding: 0 15px;
      .title {
        font-size: 26px;
        line-height: 26px;
      }
      .content {
        margin-top: 21px;
        text-indent: 2em;
        font-size: 14px;
        line-height: 26px;
      }
    }
    .contact {
      padding: 0 15px;
      .title {
        font-size: 26px;
        line-height: 26px;
        margin-top: 35px;
        margin-bottom: 26px;
      }
      .content {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 6px;
      }
      .phone {
        font-size: 16px;
        font-weight: bold;
        color: #31499F;
        line-height: 22px;
      }
    }
    .nav {
      margin-bottom: 42px;
      padding: 0 15px;
      .img-box1,
      .img-box2,
      .img-box3 {
        width: 58px;
        height: 26px;
        margin-right: 40px;
        img {
          display: block;
          width: 100%;
          height: 100%;
          // object-fit: cover;
        }
      }
      .img-box3 {
        width: 96px;
      }
    }
    .one-img {
      margin: 0 auto;
      margin-top: 22px;
      margin-bottom: 46px;
      padding: 0px 16px;
      width: 100vw;
      height: auto;
      overflow: hidden;
      border-radius: 20px;
      object-fit: cover;
      img {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 20px;
        object-fit: cover;
      }
    }
    .swiper {
      margin-top: 24px;
      margin-bottom: 24px;
      padding: 0px 10px;
      height: calc((100vw - 120px) * 700 / 1285);
      .swiper-slide {
        border-radius: 6px;
        width: calc(100vw * 0.9);
        overflow: hidden;
      }
      img {
        width: 100%;
        height: 100%;
        display: block;
        border-radius: 6px;
        object-fit: cover;
      }
      .swiper-container {
        width: 100%;
        height: 100%;
      }
    }
    .back {
      float: right;
      width: 48px;
      height: 24px;
      margin-top: 18px;
      margin-bottom: 22px;
      margin-right: 15px;
    }
  }
}

</style>
